import React from 'react';
import { useSelector } from 'react-redux';

import Layout from '../../components/MenuLayout';
import Loading from '../../components/Common/Loading';
import { PatientsView } from '../../components/Patient/PatientsView';

export default () => {
  const hospital = useSelector((state) => state.hospital && state.hospital);
  return (
    <Layout>
      {(hospital && hospital.uuid && typeof hospital.uuid === 'string')
        ? <PatientsView hospitalUuid={hospital.uuid} />
        : <Loading />}
    </Layout>
  );
};
