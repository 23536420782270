import React from 'react';
import { useSelector } from 'react-redux';

import CollapsedWrapper from '../Common/styled/CollapsedWrapper';
import { NoCases, PatientCaseCard } from '../Common/CaseCard/CaseCard';

export const PatientMedicalCasesCollapse = ({ item }) => {
  const hospital = useSelector((state) => state.hospital);
  const patient = item.node;

  return hospital && hospital.menuOptionMedicalCases && (
    <CollapsedWrapper>
      {
        !!patient.medicalCases && patient.medicalCases.length
          ? patient.medicalCases.map((medicalCase) => <PatientCaseCard medicalCase={medicalCase} key={medicalCase.uuid} />)
          : <NoCases />
      }
    </CollapsedWrapper>
  );
};

export default PatientMedicalCasesCollapse;
